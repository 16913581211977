import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { HC_KYC, HELP_CENTRE } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'KYC',
    url: HC_KYC,
  },
]

const KYC: React.FC = () => {
  const [helpCentreKycFaqsSt, setHelpCentreKycFaqsSt] = useState<Faq[]>([])
  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ helpCentreKycFaqs }) =>
      setHelpCentreKycFaqsSt(helpCentreKycFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="KYC Help Centre: Get Help on KYC & How To Do KYC on Mega App"
        description="Visit our KYC help centre for answers to queries on why is KYC mandatory in Mega, how to do KYC in the app, among others. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(helpCentreKycFaqsSt)}
      />
      <GettingStartedContent pageIndex={2} faqs={helpCentreKycFaqsSt} />
    </Layout>
  )
}

export default KYC
